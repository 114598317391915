import masasaImg from './images/masasa.jpg'
import balagbagImg from './images/mt-balagbag2.jpg'
import maynoba from './images/maynoba.jpeg'
import quezon from './images/quezon.jpg'

export default [
    {   id: 1,
        title: "Masasa Beach",
        location: "Tingloy, Batangas",
        googleMapsLink: "https://goo.gl/maps/5viPph9dmf1N8Wz29",
        startDate: "25 Jan, 2018",
        endDate: "28 Jan, 2018",
        description: "Masasa Beach is arguably the best beach in Tingloy, the only municipality in Batangas that is not on mainland Luzon. Its main island is the fish-shaped Maricaban, off the tip of Calumpang Peninsula separating Balayan Bay from Batangas Bay.",
        imageUrl: masasaImg
    }, 
    {   id: 2,
        title: "Mount Balagbag",
        location: "Norzagaray, Bulacan",
        googleMapsLink: "https://goo.gl/maps/KNWUTh9re5gbved46",
        startDate: "18 Oct, 2018",
        endDate: "18 Oct, 2018",
        description: "Mt. Balagbag is located in Sitio Balagbag San Jose Del Monte, Bulacan. It’s categorized as a minor climb, has a difficulty level of 3/9 and stands 777 MASL. It’s best for someone who wants to try outdoor activities such mountain climbing and chasing waterfalls.",
        imageUrl: balagbagImg
    }, 
    {   id: 3,
        title: "Maynoba Circuit",
        location: "Antipolo Rizal",
        googleMapsLink: "https://goo.gl/maps/1ehDZrpeQ4m5dkkr8",
        startDate: "29 Sept, 2019",
        endDate: "29 Sept, 2019",
        description: "The trip includes Mt. Maynoba, Mt. Cayabu and a side trip to the 8 wonder falls nearby the area. It’s one of the newest mountain destinations in Tanay, Rizal. One feature that you can see there is the sea of clouds present covering the valley surrounded by the mountain ranges.",
        imageUrl: maynoba
    },
    {   id: 4,
        title: "Cagbalete Island",
        location: "Mauban, Quezon",
        googleMapsLink: "https://goo.gl/maps/AeG7mtkqCbc8RoFb9",
        startDate: "31 Oct, 2019",
        endDate: "2 Nov, 2019",
        description: "Cagbalete Island is one of the jewels of Quezon Province. Located off the coast of the town of Mauban, it is a small island paradise in the province surrounded with cream sand that exposes significantly during low tide.",
        imageUrl: quezon
    }
]