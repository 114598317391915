import React from "react"

export default function Header() {
    return (
        <div className="header">
            <div className="cc-container cc-container-flex">
                <i className="fa-solid fa-earth-asia header-icon"></i>
                <p className="header-title">my travel journal.</p>
            </div>
        </div>
    )
}